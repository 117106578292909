angular.module("app")
    .directive("cHeader", function($window, $rootScope, _view, _v, _vRoutes, _appStorage, _flashService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: "t-c-header",
            controllerAs: 'vm',
            controller: function cHeaderController() {
                var vm = this;

                vm.navigation = _view.getNavigation;
                vm.isFarmAndFieldSelected = _view.isFarmAndFieldSelected;
                vm.isFarmSelected = _view.isFarmSelected;
                vm.isFieldSelected = _view.isFieldSelected;
                vm.currentField = _view.getCurrentField;
                vm.currentFarm = _view.getCurrentFarm;

                vm.dropDownAnimated = true;

                vm.adminUrl = _appStorage.get('admin_url');

                vm.setField = function(field) {
                    vm.dropDownAnimated = false;
                    $rootScope.$broadcast('_view:closeAllScreens');
                    _flashService.put('panRequiredEvent', true);
                    _v.change({set: {field: field.id, pfield: undefined, farm: field.farmId, lat: undefined, lng: undefined, zoom: undefined, viewCropMonitor: undefined, addCropMonitor: undefined, viewSpray: undefined, editSpray: undefined}});
                };

                vm.setFarm = function(farm) {
                    vm.dropDownAnimated = false;
                    $rootScope.$broadcast('_view:closeAllScreens');
                    _flashService.put('panRequiredEvent', true);
                    _v.change({set: {field: undefined, pfield: undefined, farm: farm.id, lat: undefined, lng: undefined, zoom: undefined, viewCropMonitor: undefined, addCropMonitor: undefined, viewSpray: undefined, editSpray: undefined}});
                };

                vm.openSettings = function() {
                    vm.dropDownAnimated = false;
                    $rootScope.$broadcast('_view:closeAllScreens');
                    _v.change({set: {settings: true, viewCropMonitor: undefined, addCropMonitor: undefined, viewSpray: undefined, editSpray: undefined}});
                };

                vm.logout = function() {
                    vm.dropDownAnimated = false;
                    _appStorage.remove('token');
                    window.location.href = _vRoutes.auth.login;
                };

                vm.followAdminUrl = function() {
                    vm.dropDownAnimated = false;
                    _appStorage.remove('token');
                    _appStorage.remove('admin_url');
                    $window.location.href = vm.adminUrl;
                };
            }
        }
    });